import UserRegistrationMap from 'user_registration/map';
import InvisibleRecaptcha from 'user_registration/invisible_recaptcha';
import OAuthLoginPrompt from 'user_registration/oauth_login_prompt';

const SCF = window.SCF || {};

SCF.UserRegistration = {
  Map: UserRegistrationMap,
  Recaptcha: InvisibleRecaptcha,
  Prompt: OAuthLoginPrompt
};

window.SCF = SCF;
