/* global RSVP, application */

import OAuthPopupPrompt from 'user_registration/oauth_popup_prompt';

function OAuthLoginPrompt(opts) {
  opts = opts || {};
  $.extend(this.options, opts);
  this.loggedIn = application.user.getUser().logged_in;
}

OAuthLoginPrompt.prototype.user = function() {
  trackLoginPromptEvent('1 - authenticated action requested');

  if (this.loggedIn) {
    trackLoginPromptEvent('2 - already logged in');
    return RSVP.resolve(application.user.getUser());
  } else {
    trackLoginPromptEvent('2 - authentication requested');
    return this.displayPrompt();
  }
};

OAuthLoginPrompt.prototype.displayPrompt = function() {
  var deferred = RSVP.defer();
  OAuthPopupPrompt
    .open(window, 600, 500)
    .then(() => {
      $.getJSON('/scf/v1/current_user', user => {
        application.user._set_user_data(user, false);
        return deferred.resolve(user);
      });
    })
    .catch(() => {
      deferred.reject(application.trans('user_login_prompt.dismissed'));
    });
  return deferred.promise;
};

function trackLoginPromptEvent(label) {
  var ga = window.ga || function() {};
  ga('send', 'event', 'UserLoginPrompt', label);
}

export default OAuthLoginPrompt;
