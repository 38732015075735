import I18n from 'i18n';

export default class InvisibleRecaptcha {
  constructor(container, options = {}) {
    const TIMEOUT = 30000; // 30 seconds

    this.container = container;

    this.timeout = setTimeout(this.displayError, TIMEOUT);

    this.sitekey = options.sitekey;
    this.badge = options.badge || 'bottomright';

    this.onSuccess = options.onSuccess || function() {
      this.container.disabled = 'disabled';
      document.querySelector('form').submit();
    };

    this.onSuccess = this.onSuccess.bind(this);
    this.onLoaded = this.onLoaded.bind(this);

    this.displayError = this.displayError.bind(this);
    this.execute = this.execute.bind(this);
    this.reset = this.reset.bind(this);
  }

  execute() {
    this.grecaptcha.execute(this.widgetId);
  }

  reset() {
    this.grecaptcha.reset(this.widgetId);
  }

  // aka callback for grecaptcha load. Renders the recaptcha into the container
  // and clears the timeout
  //
  onLoaded(grecaptcha) {
    this.grecaptcha = grecaptcha;

    clearTimeout(this.timeout);

    this.widgetId = grecaptcha.render(this.container, {
      sitekey: this.sitekey,
      badge: this.badge,
      size: 'invisible',
      'error-callback': this.displayError,
      callback: this.onSuccess
    });
  }

  displayError() {
    const errorMessage = document.createElement('p');
    errorMessage.innerText = I18n.t('javascript.user_registration.recaptcha.error');

    this.container.parentNode.replaceChild(errorMessage, this.container);
  }
}
