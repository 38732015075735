import I18n from 'i18n';

export default class UserRegistrationMap {
  constructor(options) {
    this.onUpdateLatLng = options.onUpdateLatLng;
    this.onUpdateAddress = options.onUpdateAddress;

    this.updateMarker = this.updateMarker.bind(this);
    this.updateMarkerPosition = this.updateMarkerPosition.bind(this);
    this.getUserPositionFromGeolocation = this.getUserPositionFromGeolocation.bind(this);

    this.google = options.google || window.google;
  }

  getUserPosition(lat, lng, address) {
    if (lat != '' && lng != '') {
      lat = parseFloat(lat);
      lng = parseFloat(lng);

      this.updateLatLng(lat, lng);
      if (address == '') {
        this.reverseGeocode(lat, lng);
      }
    } else if (address != '') {
      this.forwardGeocode(address);
    } else {
      this.getUserPositionFromGeolocation();
    }
  }

  getUserPositionFromGeolocation() {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;

        this.updateLatLng(lat, lng);
        this.reverseGeocode(lat, lng);
      });
    } catch (e) {
      // lookup failed. So sorry ...
    }
  }

  reverseGeocode(lat, lng) {
    this.geocoder.geocode({ latLng: { lat: lat, lng: lng } }, (
      results,
      status
    ) => {
      if (status == this.google.maps.GeocoderStatus.OK) {
        this.onUpdateAddress(results[0].formatted_address);
      } else {
        alert(I18n.t('javascript.user_registration.map.error_geocoding'));
      }
    });
  }

  forwardGeocode(address) {
    this.geocoder.geocode({ address: address }, (results, status) => {
      if (status == this.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        this.updateLatLng(location.lat(), location.lng());
      } else {
        alert(I18n.t('javascript.user_registration.map.error_geocoding'));
      }
    });
  }

  updateMarker(lat, lng) {
    if (!this.marker) {
      this.initializeMarker();
    }
    this.marker.setPosition({ lat: lat, lng: lng });
    this.updateLatLngInput(lat, lng);
  }

  updateLatLngInput(lat, lng) {
    this.onUpdateLatLng(lat, lng);
  }

  updateLatLng(lat, lng) {
    this.updateMarker(lat, lng);
    this.map.setCenter({ lat: lat, lng: lng });
    this.updateLatLngInput(lat, lng);
  }

  initializeMarker() {
    this.marker = new this.google.maps.Marker({ map: this.map, draggable: true });
    this.marker.addListener('dragend', this.updateMarkerPosition);
  }

  updateMarkerPosition() {
    const position = this.marker.getPosition();

    this.reverseGeocode(position.lat(), position.lng());
    this.updateLatLngInput(position.lat(), position.lng());
  }

  initializeMap(mapElement) {
    this.geocoder = new this.google.maps.Geocoder();

    const mapOptions = {
      zoom: 17,
      center: new this.google.maps.LatLng(41.308274, -72.92788350000001),
      scrollwheel: false,
      mapTypeId: this.google.maps.MapTypeId.ROADMAP,
      streetViewControl: false
    };

    this.map = new this.google.maps.Map(mapElement, mapOptions);

    this.map.addListener('click', e => {
      if (!this.marker) {
        const position = e.latLng;
        this.updateMarker(position.lat(), position.lng());
        this.reverseGeocode(position.lat(), position.lng());
      }
    });
  }
}
