class OAuthPopupPrompt {
  constructor(parentWindow, height, width) {
    this.signinUrl = '/oauth/login';
    this.signupUrl = '/oauth/signup';
    this.id = 'oauth-popup-window-' + (new Date().getTime());
    this.parentWindow = parentWindow;
    this.height = height;
    this.width = width;
    this.handlePostMessage = this.handlePostMessage.bind(this);
  }

  handlePostMessage(event) {
    if (event.data.message === 'deliverResult') {
      this.response = event.data.result;
    }
  }

  open() {
    const y = this.parentWindow.top.outerHeight / 2 + this.parentWindow.top.screenY - ( this.height / 2);
    const x = this.parentWindow.top.outerWidth / 2 + this.parentWindow.top.screenX - (this.width / 2);
    this.window = this.parentWindow.open(this.signinUrl, this.id, `width=${this.width}, height=${this.height}, top=${y}, left=${x}`);
    this.window.focus();
  }

  close() {
    this.cancel();
    this.window.close();
    window.removeEventListener('message', this.handlePostMessage);
  }

  poll() {
    this.promise = new Promise((resolve, reject) => {
      this.window.addEventListener('load', () => {
        this.windowTimer = window.setInterval(() => {
          try {
            if (!this.window|| this.window.closed !== false) {
              reject();
              this.close();

              return true;
            }

            const currentUrl = this.window.location.href.split('?')[0];
            if (currentUrl.includes('/oauth/')) {
              return true;
            }

            resolve();
            this.close();
          } catch (e) {
            // do nothing
          }
        }, 300);
      });
    });
  }

  cancel() {
    if (this.windowTimer) {
      window.clearInterval(this.windowTimer);
      this.windowTimer = null;
    }
  }

  then(...args) {
    return this.promise.then(...args);
  }

  static open(...args) {
    const popupWindow = new this(...args);

    popupWindow.open();
    popupWindow.poll();

    return popupWindow;
  }
}

export default OAuthPopupPrompt;
